@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/OpenSans-Bold.woff2') format('woff2'),
  url('assets/fonts/OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/OpenSans-SemiBold.woff2') format('woff2'),
  url('assets/fonts/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/OpenSans-Regular.woff2') format('woff2'),
  url('assets/fonts/OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/OpenSans-Light.woff2') format('woff2'),
  url('assets/fonts/OpenSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body a {
  color: inherit;
  text-decoration: none;
}

body p {
  letter-spacing: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

form, .form {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-arrow {
  display: none !important;
}

.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: auto;
  margin: 40px 0 55px;
}

.slick-dots li {
  width: 14px;
  height: 14px;
  margin: 0 7px;
}

.slick-dots li button {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #d8d8d8;
}

.slick-dots li.slick-active button {
  background-color: #0052a5;
}

.slick-dots li button:before {
  content: none;
}


.view__update_snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}

.view__update_snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}
