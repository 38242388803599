.view__diagnosis {
  .container {
    .content {
      grid-area: content;
      align-self: start;
      padding-top: 30px;
    }

    .fieldset {
      margin-bottom: 30px;
    }
  }
}
